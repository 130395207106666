// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-light-camera-black-like-meow-js": () => import("./../../../src/pages/light-camera/black-like-meow.js" /* webpackChunkName: "component---src-pages-light-camera-black-like-meow-js" */),
  "component---src-pages-light-camera-dennis-rodman-js": () => import("./../../../src/pages/light-camera/dennis-rodman.js" /* webpackChunkName: "component---src-pages-light-camera-dennis-rodman-js" */),
  "component---src-pages-light-camera-js": () => import("./../../../src/pages/light-camera.js" /* webpackChunkName: "component---src-pages-light-camera-js" */),
  "component---src-pages-shoyaright-js": () => import("./../../../src/pages/shoyaright.js" /* webpackChunkName: "component---src-pages-shoyaright-js" */),
  "component---src-pages-shoyaright-vol-1-atlantique-js": () => import("./../../../src/pages/shoyaright/vol1/atlantique.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-atlantique-js" */),
  "component---src-pages-shoyaright-vol-1-baby-rappers-js": () => import("./../../../src/pages/shoyaright/vol1/baby-rappers.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-baby-rappers-js" */),
  "component---src-pages-shoyaright-vol-1-blm-js": () => import("./../../../src/pages/shoyaright/vol1/blm.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-blm-js" */),
  "component---src-pages-shoyaright-vol-1-chomsky-sunni-js": () => import("./../../../src/pages/shoyaright/vol1/chomsky-sunni.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-chomsky-sunni-js" */),
  "component---src-pages-shoyaright-vol-1-collis-dbz-js": () => import("./../../../src/pages/shoyaright/vol1/collis-dbz.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-collis-dbz-js" */),
  "component---src-pages-shoyaright-vol-1-coronavirus-vaccine-js": () => import("./../../../src/pages/shoyaright/vol1/coronavirus-vaccine.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-coronavirus-vaccine-js" */),
  "component---src-pages-shoyaright-vol-1-drogas-js": () => import("./../../../src/pages/shoyaright/vol1/drogas.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-drogas-js" */),
  "component---src-pages-shoyaright-vol-1-js": () => import("./../../../src/pages/shoyaright/vol1.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-js" */),
  "component---src-pages-shoyaright-vol-1-mt-shoya-js": () => import("./../../../src/pages/shoyaright/vol1/mt-shoya.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-mt-shoya-js" */),
  "component---src-pages-shoyaright-vol-1-quarantine-recipes-js": () => import("./../../../src/pages/shoyaright/vol1/quarantine-recipes.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-quarantine-recipes-js" */),
  "component---src-pages-shoyaright-vol-1-shoya-casio-js": () => import("./../../../src/pages/shoyaright/vol1/shoya-casio.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-shoya-casio-js" */),
  "component---src-pages-shoyaright-vol-1-silver-lining-js": () => import("./../../../src/pages/shoyaright/vol1/silver-lining.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-silver-lining-js" */),
  "component---src-pages-shoyaright-vol-1-us-iran-js": () => import("./../../../src/pages/shoyaright/vol1/us-iran.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-us-iran-js" */),
  "component---src-pages-shoyaright-vol-1-watching-animals-js": () => import("./../../../src/pages/shoyaright/vol1/watching-animals.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-1-watching-animals-js" */),
  "component---src-pages-shoyaright-vol-2-black-nonfiction-cinema-js": () => import("./../../../src/pages/shoyaright/vol2/black-nonfiction-cinema.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-black-nonfiction-cinema-js" */),
  "component---src-pages-shoyaright-vol-2-discipline-why-how-js": () => import("./../../../src/pages/shoyaright/vol2/discipline-why-how.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-discipline-why-how-js" */),
  "component---src-pages-shoyaright-vol-2-earths-core-js": () => import("./../../../src/pages/shoyaright/vol2/earths-core.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-earths-core-js" */),
  "component---src-pages-shoyaright-vol-2-js": () => import("./../../../src/pages/shoyaright/vol2.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-js" */),
  "component---src-pages-shoyaright-vol-2-nerve-of-people-js": () => import("./../../../src/pages/shoyaright/vol2/nerve-of-people.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-nerve-of-people-js" */),
  "component---src-pages-shoyaright-vol-2-poet-foreigner-prisoner-js": () => import("./../../../src/pages/shoyaright/vol2/poet-foreigner-prisoner.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-poet-foreigner-prisoner-js" */),
  "component---src-pages-shoyaright-vol-2-replacing-sixers-js": () => import("./../../../src/pages/shoyaright/vol2/replacing-sixers.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-replacing-sixers-js" */),
  "component---src-pages-shoyaright-vol-2-shoya-borealis-js": () => import("./../../../src/pages/shoyaright/vol2/shoya-borealis.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-shoya-borealis-js" */),
  "component---src-pages-shoyaright-vol-2-street-zoology-js": () => import("./../../../src/pages/shoyaright/vol2/street-zoology.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-street-zoology-js" */),
  "component---src-pages-shoyaright-vol-2-the-arrival-of-drogas-js": () => import("./../../../src/pages/shoyaright/vol2/the-arrival-of-drogas.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-the-arrival-of-drogas-js" */),
  "component---src-pages-shoyaright-vol-2-top-5-anime-js": () => import("./../../../src/pages/shoyaright/vol2/top5-anime.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-top-5-anime-js" */),
  "component---src-pages-shoyaright-vol-2-ultimate-maga-js": () => import("./../../../src/pages/shoyaright/vol2/ultimate-maga.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-ultimate-maga-js" */),
  "component---src-pages-shoyaright-vol-2-useful-triangle-js": () => import("./../../../src/pages/shoyaright/vol2/useful-triangle.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-useful-triangle-js" */),
  "component---src-pages-shoyaright-vol-2-water-wars-js": () => import("./../../../src/pages/shoyaright/vol2/water-wars.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-2-water-wars-js" */),
  "component---src-pages-shoyaright-vol-3-crypto-butterfly-js": () => import("./../../../src/pages/shoyaright/vol3/crypto-butterfly.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-crypto-butterfly-js" */),
  "component---src-pages-shoyaright-vol-3-is-cool-a-virtue-js": () => import("./../../../src/pages/shoyaright/vol3/is-cool-a-virtue.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-is-cool-a-virtue-js" */),
  "component---src-pages-shoyaright-vol-3-js": () => import("./../../../src/pages/shoyaright/vol3.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-js" */),
  "component---src-pages-shoyaright-vol-3-logophilia-js": () => import("./../../../src/pages/shoyaright/vol3/logophilia.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-logophilia-js" */),
  "component---src-pages-shoyaright-vol-3-me-you-oldie-goldie-js": () => import("./../../../src/pages/shoyaright/vol3/me-you-oldie-goldie.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-me-you-oldie-goldie-js" */),
  "component---src-pages-shoyaright-vol-3-rare-sayings-js": () => import("./../../../src/pages/shoyaright/vol3/rare-sayings.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-rare-sayings-js" */),
  "component---src-pages-shoyaright-vol-3-shahada-99-js": () => import("./../../../src/pages/shoyaright/vol3/shahada99.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-shahada-99-js" */),
  "component---src-pages-shoyaright-vol-3-shoya-twitter-js": () => import("./../../../src/pages/shoyaright/vol3/shoya-twitter.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-shoya-twitter-js" */),
  "component---src-pages-shoyaright-vol-3-shoyotathon-23-js": () => import("./../../../src/pages/shoyaright/vol3/shoyotathon-23.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-shoyotathon-23-js" */),
  "component---src-pages-shoyaright-vol-3-the-pupil-js": () => import("./../../../src/pages/shoyaright/vol3/the-pupil.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-the-pupil-js" */),
  "component---src-pages-shoyaright-vol-3-us-russia-js": () => import("./../../../src/pages/shoyaright/vol3/us-russia.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-3-us-russia-js" */),
  "component---src-pages-shoyaright-vol-4-filmmaker-starting-5-js": () => import("./../../../src/pages/shoyaright/vol4/filmmaker-starting-5.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-filmmaker-starting-5-js" */),
  "component---src-pages-shoyaright-vol-4-free-palestine-js": () => import("./../../../src/pages/shoyaright/vol4/free-palestine.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-free-palestine-js" */),
  "component---src-pages-shoyaright-vol-4-fuhrer-king-waka-js": () => import("./../../../src/pages/shoyaright/vol4/führer-king-waka.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-fuhrer-king-waka-js" */),
  "component---src-pages-shoyaright-vol-4-js": () => import("./../../../src/pages/shoyaright/vol4.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-js" */),
  "component---src-pages-shoyaright-vol-4-nardwuar-must-go-js": () => import("./../../../src/pages/shoyaright/vol4/nardwuar-must-go.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-nardwuar-must-go-js" */),
  "component---src-pages-shoyaright-vol-4-pope-sean-paul-js": () => import("./../../../src/pages/shoyaright/vol4/pope-sean-paul.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-pope-sean-paul-js" */),
  "component---src-pages-shoyaright-vol-4-the-iron-in-your-soul-js": () => import("./../../../src/pages/shoyaright/vol4/the-iron-in-your-soul.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-the-iron-in-your-soul-js" */),
  "component---src-pages-shoyaright-vol-4-zuff-gpt-2-js": () => import("./../../../src/pages/shoyaright/vol4/zuff-gpt-2.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-zuff-gpt-2-js" */),
  "component---src-pages-shoyaright-vol-4-zuff-gpt-js": () => import("./../../../src/pages/shoyaright/vol4/zuff-gpt.js" /* webpackChunkName: "component---src-pages-shoyaright-vol-4-zuff-gpt-js" */),
  "component---src-pages-shoyaright-volumes-js": () => import("./../../../src/pages/shoyaright/volumes.js" /* webpackChunkName: "component---src-pages-shoyaright-volumes-js" */)
}

